import '/assets/styles/layouts/base.scss';
import PropTypes from 'prop-types';

// Sections
import Header from '/views/sections/header'
import Footer from '/views/sections/footer'

const BaseLayout = ({ children, pageClass, hideHeader, hideFooter, plainHeader, plainFooter }) => {
	return (
		<div className={`page layout-base ${pageClass ? 'page-' + pageClass : ''}`}>
			{!hideHeader &&
				<Header plain={plainHeader} />
			}
			{children}
			{!hideFooter &&
				<Footer plain={plainFooter} />
			}
		</div>
	)
}

BaseLayout.propTypes = {
	children: PropTypes.node,
	pageClass: PropTypes.string,
}

export default BaseLayout